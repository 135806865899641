<template>
  <div class="flex items-center justify-between bg-blue-500 pt-2 pb-1">
    <div>
      <!-- <select id="" 
        class="block ml-2 py-0 px-2 border-white rounded bg-blue-500 text-white is-small border border-white">
        <option value="option1">Gjirokaster qender pos 4</option>
        <option value="option2">Option 2</option>
        <option value="option3">Option 3</option>
      </select> -->
    </div>
    <h1 class="text-white">Ditare te hapur</h1>
    <div class="flex">
      <span
        @click="toggle_discount_request_list()"
        class="flex items-center text-white px-2 mr-2 border border-white rounded cursor-pointer" >
        <i
          v-if="useDiaryStore().discount_list_mode"
          class="fa-regular fa-circle-dot text-14px mr-1 text-14px"
        ></i>
        <i v-else class="fa-regular fa-circle text-14px mr-1 text-14px"></i>
        Pret zbritje
      </span>
      <span
        @click="toggle_customer_waiting_mode()"
        class="flex items-center text-white px-2 mr-2 border border-white rounded cursor-pointer" >
        <i
          v-if="useDiaryStore().customer_waiting_mode"
          class="fa-regular fa-circle-dot text-14px mr-1 text-14px"
        ></i>
        <i v-else class="fa-regular fa-circle text-14px mr-1 text-14px"></i>
        Ne pritje nga klienti
      </span>
    </div>
  </div>
  <!-- Filters -->
  <div class="flex justify-center mt-2">
    <div class="flex border rounded w-3/1 px-1">
      <div class="flex text-12px">
        <div class="flex items-center border-r pr-1">
          <a href="" @click.prevent="useDiaryStore().resetFilters" class="mx-2 relative">
            <img
              width="14"
              height="14"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuklEQVR4nO2UMQrCMBSGP3QTPYJHcBBP4SB4Dx08hw4epIO3cJCeQbA3aEcVIk/+IRSpaSwomA865L3vzwuFBBItcR193xtgHCRlz1UYmTKWfcsYqBRYBPhzuZWyQWwUugDDBm8AnOVaJpg+kCu4bfB2cnJlWjED7sANmL7oT4CrHHOj2OuEp9oJe8BRPXOiGQGFNlp59bVqhZyPWGqz0quVqlmvE1ztAgVdqDTApV/k42Ke5Z8a8Mc8AHpXbRYf6WnfAAAAAElFTkSuQmCC"
              :style="{ 
                'opacity': useDiaryStore().filtersEnabled ? '.7' : '0.4', 
              }" />
              <div v-if="useDiaryStore().filtersEnabled" class="absolute bottom-0 right- right-0 w-1 h-1 bg-red-500 rounded-full">&nbsp;</div>
          </a>
        </div>
        <div class="flex items-center border-r px-1">
          <div class="relative flex">
            <span @click="useDiaryStore().show_status_dropdown = !useDiaryStore().show_status_dropdown" 
              class="text-slate-400 cursor-pointer">
              Statusi
              <i class="fa-solid fa-angle-down mx-1"></i>
            </span>
            <div v-if="useDiaryStore().show_status_dropdown" 
              class="absolute right-0 border rounded dropdown-box-shadow flex flex-col bg-white cursor-pointer"
              style="top:20px;">
              <span @click="useDiaryStore().diary_filter_status = null, useDiaryStore().show_status_dropdown = null, useDiaryStore().change_page(1) "
                class="border-b py-1 pl-2 hover:bg-slate-50"
                style="white-space: nowrap;">
                Te gjithe
              </span>
              <span v-for="status in useDiaryStore().diary_statuses.filter(status => status.is_closed == 0)"
                :key="status.id"
                @click="useDiaryStore().diary_filter_status = status.id, useDiaryStore().show_status_dropdown = null, useDiaryStore().change_page(1) "
                class="border-b py-1 pl-2 pr-1 hover:bg-slate-50"
                :class="{'bg-slate-50': status.id == useDiaryStore().diary_filter_status}"
                style="white-space: nowrap;">
                {{ status.name }}
              </span>
            </div>
          </div>
          <!-- <select v-model="useDiaryStore().diary_filter_status">
            <option :value="null">Zgjidh statusin</option>
            <option
              v-for="status in useDiaryStore().diary_statuses"
              :key="status.id"
              :value="status.id"
              @change="useDiaryStore().filter_diaries"
            >
              {{ status.name }}
            </option>
          </select> -->
        </div>
        <div class="flex items-center gap-1 p-1 border-r">
          <input v-model="useDiaryStore().diary_filter_id"
            @keyup="useDiaryStore().change_page(1)"
            placeholder="Kerko ID"
            class="input is-small w-20"
            type="text">
        </div>
        <div class="flex items-center gap-1 p-1">
          <input type="text" 
            @keyup="useDiaryStore().change_page(1)"
            class="input is-small" 
            placeholder="Kerko..." 
            v-model="useDiaryStore().diary_filter_text" >
          <i class="fa fa-search fa-xs mx-2 text-gray-300"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2 flex flex-col">
    <div style="height: calc(100vh - 213px);">
      <table>
        <thead>
          <tr class="table-header">
            <th class="text-center w-16" @click="useDiaryStore().sortBy('id')">
              #
            </th>
            <th @click="useDiaryStore().sortBy('customer_name')">
              <div class="flex justify-between items-center">
                <span> Klienti </span>
                <div>
                  <i
                    class="fa fa-sort-down"
                    v-if="useDiaryStore().sort['customer_name'] == 'desc'"
                  ></i>
                  <i
                    class="fa fa-sort-up"
                    v-else-if="useDiaryStore().sort['customer_name'] == 'asc'"
                  ></i>
                </div>
              </div>
            </th>

            <th @click="useDiaryStore().sortBy('status')">
              <div class="flex justify-between items-center">
                <span> Statusi </span>
                <div>
                  <i
                    class="fa fa-sort-down"
                    v-if="useDiaryStore().sort['status'] == 'desc'"
                  ></i>
                  <i
                    class="fa fa-sort-up"
                    v-else-if="useDiaryStore().sort['status'] == 'asc'"
                  ></i>
                </div>
              </div>
            </th>

            <th @click="useDiaryStore().sortBy('created_at')">
              <div class="flex justify-between items-center">
                <span> Krijuar </span>
                <div>
                  <i
                    class="fa fa-sort-down"
                    v-if="useDiaryStore().sort['created_at'] == 'desc'"
                  ></i>
                  <i
                    class="fa fa-sort-up"
                    v-else-if="useDiaryStore().sort['created_at'] == 'asc'"
                  ></i>
                </div>
              </div>
            </th>
            <th @click="useDiaryStore().sortBy('valid_until')">
              <div class="flex justify-between items-center">
                <span> Skadon me </span>
                <div>
                  <i
                    class="fa fa-sort-down"
                    v-if="useDiaryStore().sort['valid_until'] == 'desc'"
                  ></i>
                  <i
                    class="fa fa-sort-up"
                    v-else-if="useDiaryStore().sort['valid_until'] == 'asc'"
                  ></i>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="diary in useDiaryStore().diaries_list" :key="diary.id">
            <td
              class="hover:bg-green-200 cursor-pointer"
              @click.prevent="openDiary(diary.guid)"
            >
              <a class="block text-center">
                <i class="fa fa-hashtag text-gray-300 fa-xs"></i>
                {{ diary.id }}
              </a>
            </td>
            <td>
              <div class="flex items-center justify-between px-2">
                <span v-if="diary.customer_name">
                  <i class="fa fa-user text-gray-400"></i>
                  {{ diary.customer_name }}
                </span>
                <span>
                  <i class="fa fa-list"></i>
                </span>
              </div>
            </td>
            <td>
              <div class="p-2 flex justify-between items-center">
                <div class="flex flex-col">
                  <span
                    @click="openDiaryDiscount(diary)"
                    :style="{
                      backgroundColor: diary.bg_color,
                      color: diary.text_color,
                    }"
                    class="p-1 px-2 rounded text-xs"
                    :class="{'cursor-pointer': diary.status_id == 6}"
                    style="width: fit-content;"
                  >
                    <i class="fa fa-circle fa-xs"></i>
                    {{ diary.status }}
                  </span>
                  <span v-if="diary.requested_by_customer"
                    class="p-1 px-2 rounded text-xs bg-teal-300 mt-2"
                    :class="{'cursor-pointer': diary.status_id == 6}"
                  >
                    <i class="fa fa-circle fa-xs"></i>
                    Ne pritje te konfirmimit te klientit
                  </span>
                </div>
                <span class="text-gray-400 flex flex-col items-end text-xs">
                  <span>
                    {{ diary.status_created_by }}
                  </span>
                  <span class="font-thin">
                    {{ moment(diary.status_created_at).fromNow() }}
                  </span>
                </span>
              </div>
            </td>
            <td>
              <div class="p-2 flex justify-between items-center">
                <span>
                  {{ diary.name }}
                </span>
                <span class="text-gray-400 text-xs">
                  {{ moment(diary.status_created_at).format("DD/MM/YYYY HH:mm") }}
                </span>
              </div>
            </td>
            <td :class="[getStatusClass(diary)]">
              <div class="p-2 flex items-center">
                <span class="text-gray-500 text-xs ml-auto">
                  {{ moment(diary.valid_until).format("DD/MM/YYYY HH:mm") }}
                </span>
              </div>
              <div v-if="diary.requested_by_customer && !diary.reject_reason_text"
                class="flex flex-col text-12px text-right border-t border-b px-2">
                <span>Ne pritje te konfirmimit nga klienti</span>
                <span class="">
                  {{ moment(diary.requested_by_customer).format("DD/MM/YYYY HH:mm") }}
                </span>
              </div>
              <div v-if="diary.requested_text && !diary.reject_reason_text"
                class="flex flex-col text-12px text-right border-t border-b px-2">
                <span>Arsyet e pritjes</span>
                <span class="">
                  {{ diary.requested_text }}
                </span>
              </div>
              <div v-if="diary.reject_reason_text"
                class="flex flex-col text-12px text-right border-t border-b px-2">
                <span>Pritje e anulluar nga klienti: </span>
                <span class="">
                  {{ diary.reject_reason_text }}
                </span>
                <span v-if="diary.reject_text">
                  {{diary.reject_text}}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination flex justify-center">
      <Dapaginator :totalPages="useDiaryStore().total_pages" :currentPage="useDiaryStore().current_page" @changePage="useDiaryStore().change_page"></Dapaginator>
    </div>
  </div>
</template>

<script setup>
import { useDiaryStore } from "@/stores/diary";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import Dapaginator from '@/components/Dapaginator.vue'

const router = useRouter();

const openDiary = (id) => {
  router.push({ name: "Ditar Shiko", params: { id } });
};
const openDiaryDiscount = (diary) => {
  if (diary.status_id == 6 ) {
    router.push({ name: "Konfirmo zbritje", params: { id: diary.discount_request_id } });
  }
};

const getStatusClass = (diary) => {
  const expirationDate = moment(diary.valid_until);
  const currentDate = moment();

  if (expirationDate.isBefore(currentDate)) {
    return "bg-red-100";
  } else if (
    expirationDate.isSameOrAfter(currentDate) &&
    expirationDate.diff(currentDate, "minutes") <= 5
  ) {
    return "bg-yellow-100";
  } else {
    return "bg-green-100";
  }
};

const toggle_discount_request_list = async () => {
  useDiaryStore().discount_list_mode = !useDiaryStore().discount_list_mode;
  useDiaryStore().customer_waiting_mode = false;
};

const toggle_customer_waiting_mode = async () => {
  useDiaryStore().discount_list_mode = false;
  useDiaryStore().customer_waiting_mode = !useDiaryStore().customer_waiting_mode;
};



onMounted(async () => {
  await useDiaryStore().getDiaries();
  // Get statuses
  await useDiaryStore().get_diary_statuses();
  useDiaryStore().current_page = 1
});
</script>

<style scoped>
table,
th,
td {
  border: 1px solid #ccc;
  border-collapse: collapse;
}

th,
td {
  vertical-align: middle;
}

table tr:nth-child(even) {
  background-color: #fff;
}

table tr:nth-child(odd) {
  background-color: #fff;
}

table {
  width: 100%;
}

table tr:hover {
  background-color: #ccc;
}

thead th {
  background-color: #c4deff;
  color: #545454;
  font-weight: 400;
}

/* Define a class for the table header */
.table-header {
  background-color: #000; /* Black background color */
  color: #fff; /* White text color */
  font-weight: bold; /* Bold font for better readability */
  text-align: left; /* Align text to the left by default */
  border-bottom: 2px solid #333; /* Add a bottom border for separation */
}

/* Optionally, style table cells within the header */
.table-header th {
  padding: 0 10px; /* Add padding to th elements for spacing */
}

/* Example hover effect for table header cells */
.table-header th:hover {
  background-color: #333; /* Darken the background on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
  color: #fff; /* White text color on hover */
}

.dropdown-box-shadow{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
</style>
